import { useContext, useEffect, useState, useMemo } from 'react'

import { Routes, Route } from 'react-router-dom';
import { MyContext } from '../contexts/MyContext'
import RowReactTableFilter from './ReactTable/RowReactTableFilter'

import ReportDetails from '../pages/ReportDetails';

function RowGetViewTableLong(passdata) {
    const { tableGet } = useContext(MyContext);

    const initialState = {
        // url: passdata.url,
        success: true,
        // headdata: [],
        // id: '',
        tabledata: [],
        errorMsg: '',
        successMsg: '',
    }

    const [state, setState] = useState(initialState);

    useEffect(() => tableGet(passdata.url)
        .then((data) => {
            if (data.success && data.tabledata) {
                setState({
                    ...state,
                    success: true,
                    tabledata: data.tabledata,
                });
            } else {
                setState({
                    ...state,
                    success: false,
                    errorMsg: data.message
                });
            }

        }), [passdata])

    const data = useMemo(() => state.tabledata, [state.tabledata])

    const columns = useMemo(() => passdata.col, [passdata.col])


    return (state.success) ?
        <Routes>
            <Route path="/" element={<RowReactTableFilter columns={columns} data={data} />} />
            <Route path=":theId" element={<ReportDetails detaildata={data} />} />
        </Routes>
        : <>{state.errorMsg}</>

};

export default RowGetViewTableLong
