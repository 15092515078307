import { useContext, useState } from 'react';
import { Routes, Route, NavLink, useNavigate, Link } from 'react-router-dom';

import { MyContext } from '../contexts/MyContext';
import NewTicketFAQ from '../components/NewTicketFAQ'
import RowText from '../components/rows/RowText';
import RowTextArea from '../components/rows/RowTextArea';

import ItemRadio from '../components/rows/RowItemRadio';


import RowGetViewTableToSelect from "../components/RowGetViewTableToSelect"
import './form.css'

function NewTicket(baseformdata) {
    const navigate = useNavigate();

    const { newTicketRecord } = useContext(MyContext);
    const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();
    const initialFormData = {

        formInfo: {

            type: '',
            issue: 'charing',

            check_date: theTime.substring(0, theTime.length - 5),
            end_date: '',

            phone: '',
            name: '',
            member_no: '',

            urgent_reply: '',
            normal_reply: 'No',
            reply_no: '',

            result: '',
            result_text: '',
            result_need: '',

            site: '',

            cid: '',
            penalty_amount: '',

            coupon_code_need: '',
            coupon_code: '',
            coupon_code_list: [],

            manual_start_info: {

                charging_id: '',
                user_platform: '',
                app_version: '',

                phone_os: '',
                phone_model: '',
                pid: '',
                plan: '',
                ev_model: '',
                final_charged_energy: '',
                client_status_time: '',
                error_code: '',
                problem_description: '',
                action_done: '',
                ms_result: '',

                ms_recharged_result: '',
                ms_refund_type: '',
            },
        },
        errorMsg: '',
        successMsg: '',
    }
    const [FormData, setFormData] = useState(initialFormData);

    // On Submit the Porject Form
    const submitForm = async (event) => {
        event.preventDefault();
        console.log("submit", FormData.formInfo)
        const data = await newTicketRecord(FormData.formInfo);
        if (data.success) {
            setFormData({
                ...initialFormData,
                successMsg: data.message,
            })
            navigate('/')
        }
        else {
            setFormData({
                ...FormData,
                successMsg: '',
                errorMsg: data.message
            });

        }
    }
    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                [e.target.name]: e.target.value,
            }
        });
    }

    const onChangeValueCeckTime = (e) => {
        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                [e.target.name]: e.target.value,
                end_date: e.target.value,

            }
        });
    }

    const onChangeValueMSForm = (e) => {
        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                manual_start_info: {
                    ...FormData.formInfo.manual_start_info,
                    [e.target.name]: e.target.value,
                }
            }

        })
        if (e.target.name === 'ms_recharged_result' && e.target.value === 'success') {
            setFormData({
                ...FormData,
                formInfo: {
                    ...FormData.formInfo,
                    manual_start_info: {
                        ...FormData.formInfo.manual_start_info,
                        [e.target.name]: e.target.value,

                        ms_refund_type: "",
                    }
                }
            });
        };
    }

    // On change the Radio
    const onChangeRadioTextNeed = (e) => {
        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                [e.target.name]: e.target.value,
                result_need: e.target.getAttribute("data-text-type"),
                coupon_code_need: '',
                coupon_code_list: [],
            }
        });
    }

    const onChangeRadioMSFormTextNeed = (e) => {
        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                coupon_code_need: e.target.getAttribute("data-text-type"),
                manual_start_info: {
                    ...FormData.formInfo.manual_start_info,
                    [e.target.name]: e.target.value,
                }
            }
        });
    }


    const addCouponCode = (coupon) => {
        if (FormData.formInfo.coupon_code !== '') {
            const list = FormData.formInfo.coupon_code_list;

            list.push(coupon);
            setFormData({
                ...FormData,
                list,
                formInfo: {
                    ...FormData.formInfo,
                    coupon_code: '',
                }
            });
        }

    }

    const deleteCouponCode = (index) => {
        const list = FormData.formInfo.coupon_code_list;
        list.splice(index, 1);

        setFormData({
            ...FormData,
            list,
        });

        // console.log(FormData.formInfo)
    }


    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (FormData.errorMsg) {
        errorMsg = <div className="error-msg">{FormData.errorMsg}</div>;
    }
    if (FormData.successMsg) {
        successMsg = <div className="success-msg">{FormData.successMsg}</div>;
    }

    return (
        <>
            <h1>New Ticket</h1>
            <div className="row newticket pt-2">
                <form onSubmit={submitForm} noValidate>

                    <h2>基本資料</h2>
                    <section className="mb-4 mt-3 p-2">
                        <div className="row">
                            <h3>表格資料</h3>
                        </div>
                        <div className='row mb-3'>
                            <div className='col'>
                                <label className='form-label'>查詢途徑<span style={{ color: "red" }}> * </span>:</label>
                                <ItemRadio name='type' id='type1' onChange={onChangeValue} textrequire='0' text='phone' value='phone' />
                                <ItemRadio name='type' id='type2' onChange={onChangeValue} textrequire='0' text='whatsapp' value='whatsapp' />
                                <ItemRadio name='type' id='type3' onChange={onChangeValue} textrequire='0' text='AMS' value='ams' />
                            </div>
                            <div className='col' >
                                <label className='form-label'>問題類別<span style={{ color: "red" }}> * </span>:</label>
                                <ItemRadio name='issue' id='issue1' onChange={onChangeValue} textrequire='0' text='一般' value='enquiry' />
                                <ItemRadio name='issue' id='issue2' onChange={onChangeValue} textrequire='0' text='充電' value='charing' defaultChecked='true' />
                            </div>
                            <div className='col-6'>
                                <label className='form-label'>充電站<span style={{ color: "red" }}> * </span>:</label>
                                <RowGetViewTableToSelect
                                    url="ticket-site-get.php"
                                    id="site"
                                    value={FormData.formInfo.supplier}
                                    onChange={onChangeValue}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <RowText
                                    id='check_date'
                                    type='datetime-local'
                                    value={FormData.formInfo.check_date}
                                    onChange={onChangeValueCeckTime}
                                    text='查詢日期/時間'
                                    placeholder='查詢日期/時間'
                                    step={1}
                                />
                            </div>
                            <div className='col'>
                                <RowText
                                    id='end_date'
                                    type='datetime-local'
                                    value={FormData.formInfo.end_date}
                                    onChange={onChangeValue}
                                    text='完成日期/時間'
                                    placeholder='完成日期/時間'
                                    step={1}
                                />
                            </div>
                        </div>
                    </section>

                    <section className="mb-4 p-2">
                        <div className="row">
                            <h3>客戶資料</h3>
                        </div>
                        <div className='row mb-3'>
                            <div className='col'>
                                <RowText
                                    id='phone'
                                    type='text'
                                    value={FormData.formInfo.phone}
                                    onChange={onChangeValue}
                                    text='來電號碼'
                                    placeholder='來電號碼'
                                    required='required'
                                />
                                <a href={'https://api.whatsapp.com/send?phone=852' + FormData.formInfo.phone} target="_blank">whatsapp he phone</a>
                            </div>
                            <div className='col'>
                                <RowText
                                    id='reply_no'
                                    type='text'
                                    value={FormData.formInfo.reply_no}
                                    onChange={onChangeValue}
                                    text='回覆電話號碼'
                                    placeholder='如果與上面不同，請填號碼'
                                />
                                <a href={'https://api.whatsapp.com/send?phone=852' + FormData.formInfo.reply_no} target="_blank">whatsapp he from reply</a>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <RowText
                                    id='name'
                                    type='text'
                                    value={FormData.formInfo.name}
                                    onChange={onChangeValue}
                                    text='客戶姓名'
                                    placeholder='客戶姓名'
                                    required='required'
                                />
                            </div>
                            <div className='col'>
                                <RowText
                                    id='member_no'
                                    type='text'
                                    value={FormData.formInfo.member_no}
                                    onChange={onChangeValue}
                                    text='會員編號'
                                    placeholder='會員編號'
                                    required='required'
                                />
                            </div>
                        </div>
                    </section>

                    <section className="mb-4 p-2">
                        <div className="row">
                            <h3>回覆</h3>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className='form-label'>需要緊急回覆<span style={{ color: "red" }}> * </span>:</label>
                                <ItemRadio name='urgent_reply' id='urgent_reply1' onChange={onChangeValue} textrequire='0' text='Yes (will send email)' value='Yes' />
                                <ItemRadio name='urgent_reply' id='urgent_reply2' onChange={onChangeValue} textrequire='0' text='No' value='No' />
                            </div>
                            <div className='col'>
                                <label className='form-label'>需要一般回覆 :</label>
                                <ItemRadio name='normal_reply' id='normal_reply1' onChange={onChangeValue} textrequire='0' text='Yes' value='Yes' />
                                <ItemRadio name='normal_reply' id='normal_reply2' onChange={onChangeValue} textrequire='0' text='No' value='No' defaultChecked='true' />
                            </div>
                        </div>
                    </section>

                    <h2>查詢內容</h2>

                    <section className="mb-4 mt-3 p-2">
                        <div className="row">
                            <h3>FAQ類別</h3><small>Please select</small>
                        </div>
                        <div className="row">
                            <div className="col-auto"><NavLink to="12">Fail Charge</NavLink></div>
                            <div className="col-auto"><NavLink to="10">Manual Start</NavLink></div>
                            <div className="col-auto"><NavLink to="11">Refund</NavLink></div>
                            <div className="col-auto"><NavLink to="13">Penalty</NavLink></div>
                            <div className="col-auto"><NavLink to="2">收費</NavLink></div>
                            <div className="col-auto"><NavLink to="14">登記</NavLink></div>
                        </div>
                    </section>

                    <Routes>
                        <Route path="2" element={<NewTicketFAQ category="2" onChange={onChangeRadioTextNeed} url="ticket-faq-get.php" />} />
                        <Route path="10" element={<NewTicketFAQ category="10" onChange={onChangeRadioTextNeed} textrequire='2' url="ticket-faq-get.php" />} />
                        <Route path="11" element={<NewTicketFAQ category="11" onChange={onChangeRadioTextNeed} url="ticket-faq-get.php" />} />
                        <Route path="12" element={<NewTicketFAQ category="12" onChange={onChangeRadioTextNeed} url="ticket-faq-get.php" />} />
                        <Route path="13" element={<NewTicketFAQ category="13" onChange={onChangeRadioTextNeed} url="ticket-faq-get.php" />} />
                        <Route path="14" element={<NewTicketFAQ category="14" onChange={onChangeRadioTextNeed} url="ticket-faq-get.php" />} />
                    </Routes>


                    {(FormData.formInfo.result >= '39' && FormData.formInfo.result <= '46') &&
                        <section className="mb-4 p-2">
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <RowText
                                        id='cid'
                                        type='text'
                                        value={FormData.formInfo.cid}
                                        onChange={onChangeValue}
                                        text='CID'
                                        placeholder='CID'
                                        required='required'
                                    />
                                </div>
                            </div>
                        </section>}

                    {(FormData.formInfo.result === '45' || FormData.formInfo.result === '46') &&
                        <section className="mb-4 p-2">
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <RowText
                                        id='penalty_amount'
                                        type='text'
                                        value={FormData.formInfo.penalty_amount}
                                        onChange={onChangeValue}
                                        text='罰款金額'
                                        placeholder='$$$'
                                        required='required'
                                    />
                                </div>
                            </div>
                        </section>}


                    {FormData.formInfo.result_need === '2' && <section className="mb-4 p-2">
                        <div className="row">
                            <h3>手動開機</h3>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-sm-6'>
                                <RowText
                                    id='charging_id'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.charging_id}
                                    onChange={onChangeValueMSForm}
                                    text='Charging ID'
                                    placeholder='Charging ID'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='user_platform'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.user_platform}
                                    onChange={onChangeValueMSForm}
                                    text='User platform'
                                    placeholder='User platform'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='app_version'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.app_version}
                                    onChange={onChangeValueMSForm}
                                    text='App version'
                                    placeholder='App version'
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='phone_os'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.phone_os}
                                    onChange={onChangeValueMSForm}
                                    text='Phone OS'
                                    placeholder='Phone OS'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='phone_model'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.phone_model}
                                    onChange={onChangeValueMSForm}
                                    text='Phone model'
                                    placeholder='Phone model'
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='pid'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.pid}
                                    onChange={onChangeValueMSForm}
                                    text='PID'
                                    placeholder='PID'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='plan'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.plan}
                                    onChange={onChangeValueMSForm}
                                    text='Plan'
                                    placeholder='Plan'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='ev_model'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.ev_model}
                                    onChange={onChangeValueMSForm}
                                    text='EV model'
                                    placeholder='EV model'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='final_charged_energy'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.final_charged_energy}
                                    onChange={onChangeValueMSForm}
                                    text='Final Charged Energy (if any)'
                                    placeholder='Final Charged Energy (if any)'
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='client_status_time'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.client_status_time}
                                    onChange={onChangeValueMSForm}
                                    text='Client Status Time'
                                    placeholder='Client Status Time'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='error_code'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.error_code}
                                    onChange={onChangeValueMSForm}
                                    text='ERROR Code (if any)'
                                    placeholder='ERROR Code (if any)'
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='problem_description'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.problem_description}
                                    onChange={onChangeValueMSForm}
                                    text='Problem Description'
                                    placeholder='Problem Description'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='action_done'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.action_done}
                                    onChange={onChangeValueMSForm}
                                    text='Action done'
                                    placeholder='Action done'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <RowText
                                    id='ms_result'
                                    type='text'
                                    value={FormData.formInfo.manual_start_info.ms_result}
                                    onChange={onChangeValueMSForm}
                                    text='Result'
                                    placeholder='Result'
                                    required={(FormData.formInfo.result_need === '2') ? 'required' : ''}
                                />
                            </div>

                            <div className='col-sm-6'>
                                <label className='form-label'>手動開機結果<span style={{ color: "red" }}> * </span>:</label>
                                <ItemRadio name='ms_recharged_result' id='ms_recharged_result1' onChange={onChangeValueMSForm} textrequire='0' text='成功' value='success' />
                                <ItemRadio name='ms_recharged_result' id='ms_recharged_result2' onChange={onChangeValueMSForm} textrequire='0' text='失敗' value='fail' />
                            </div>
                            {(FormData.formInfo.manual_start_info.ms_recharged_result === 'fail') &&
                                <div className='col-sm-6'>
                                    <label className='form-label'>失敗處理<span style={{ color: "red" }}> * </span>:</label>
                                    <ItemRadio name='ms_refund_type' id='ms_refund_type1' onChange={onChangeRadioMSFormTextNeed} textrequire='0' text='Refund Request' value='refund' />
                                    <ItemRadio name='ms_refund_type' id='ms_refund_type2' onChange={onChangeRadioMSFormTextNeed} textrequire='2' text='Refund Coupon' value='coupon' />

                                </div>
                            }

                        </div>
                    </section>}



                    {(FormData.formInfo.result === '41' || (FormData.formInfo.result === '39' && FormData.formInfo.coupon_code_need === '2')) &&
                        <section className="mb-4 p-2">
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <RowText
                                        id='coupon_code'
                                        type='text'
                                        value={FormData.formInfo.coupon_code}
                                        onChange={onChangeValue}
                                        text='Coupon Code'
                                        placeholder='Coupon Code'
                                        required='required'
                                    />

                                    <input type="button" onClick={() => addCouponCode(FormData.formInfo.coupon_code)} value="Add" />

                                </div>
                                <div className='col-sm-6'>
                                    <span>Code List:</span>
                                    {FormData.formInfo.coupon_code_list.map((item, index) =>

                                        <div className="row" key={index}>
                                            <div className='col-1'>{index + 1}</div>
                                            <div className='col-3'>{item}</div>
                                            <div className='col-1'><input type="button" onClick={() => deleteCouponCode(index)} value="X" /></div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </section>}





                    <div className=''>
                        <br />
                        <ItemRadio name='result' id='result99' onChange={onChangeRadioTextNeed} textrequire='1' text='Others' value='99' />
                    </div>

                    <RowTextArea
                        id='result_text'
                        type='text'
                        value={FormData.formInfo.result_text}
                        onChange={onChangeValue}
                        text='Description'
                        placeholder='Enter your description'
                        required={(FormData.formInfo.result_need === '1') ? 'required' : ''}
                    />

                    <div className="mb-4" style={{ color: "red", }}>
                        {errorMsg}
                        {successMsg}
                    </div>

                    <div className="mb-4">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </>
    )

};

export default NewTicket