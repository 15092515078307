import RowGetViewTable from '../components/RowGetViewTable'

function Home() {
  return (
    <div className="row justify-content-md-center">
      <h1>My Record</h1>
      <RowGetViewTable url="ticket-record-get.php" />
    </div>
  );
}

export default Home;
