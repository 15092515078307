function RowItemRadioWithNo(RowItemRadioWithNo) {
    return (
        <div className="form-check">
            <input className="form-check-input"
                type="radio"
                name={RowItemRadioWithNo.name}
                id={RowItemRadioWithNo.id}
                value={RowItemRadioWithNo.value}
                onChange={RowItemRadioWithNo.onChange}
                // checked={RowItemRadioWithNo.checked}
                data-text-type={RowItemRadioWithNo.textrequire}
            />
            {/* <label style={{
                fontWeight: "bold",
                textDecorationLine: "underline"
            }} className="form-check-label" htmlFor={RowItemRadioWithNo.id}>{RowItemRadioWithNo.value + '. ' + RowItemRadioWithNo.text}</label> */}
            <label style={{
                fontWeight: "bold",
                textDecorationLine: "underline"
            }} className="form-check-label" htmlFor={RowItemRadioWithNo.id}>{RowItemRadioWithNo.text}</label>
        </div >
    );
}
export default RowItemRadioWithNo;