import { useContext } from 'react'
import { Routes, Route, NavLink } from "react-router-dom";
import { MyContext } from '../contexts/MyContext'


//base
import Login from '../pages/base/Login'
import Register from '../pages/base/Register'

//pages
import Home from '../pages/Home'
import NewTicket from '../pages/NewTicket'
import Report from '../pages/Report'


function RouterDom() {

    const { rootState, logoutUser } = useContext(MyContext);
    const { isAuth, theUser, showLogin } = rootState;

    let container = 'container-xxl flex-wrap flex-md-nowrap'

    // If user Logged in
    if (isAuth) {
        return (
            <>
                <header></header>
                <nav role="navigation" className="navbar navbar-expand-lg navbar-light bg-light">
                    {/* <div className="container-fluid"> */}
                    <div className={container}>
                        {/* <span className="navbar-brand">Hi, {theUser.name}</span> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav nav-tabs me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/">Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/report">Report</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/newticket">New Ticket</NavLink>
                                </li>
                            </ul>
                            <span className="navbar-brand">Hi, {theUser.name}</span>
                            <button className="d-flex" onClick={logoutUser}>Logout</button>
                        </div>
                    </div>
                </nav>
                <main className={container}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="report/*" element={<Report />} />

                        <Route path="newticket/*" element={<NewTicket />} />
                        <Route path="register" element={<Register />} />
                    </Routes>
                </main>
            </>
        );
    }
    // Showing Login Page According to the condition
    else if (showLogin) {
        return (
            <>
                <header></header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                </nav>
                <main className={container}>
                    <div className="row justify-content-md-center">
                        <div className="col-md-4 login">
                            <Login />
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default RouterDom;

