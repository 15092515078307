function RowText(row_data) {
    return (
        // <div className={row_data.rowCSS}>
        <div className="mb-3">
            <label htmlFor={row_data.id} className="form-label">{row_data.text}{(row_data.required === "required" ? <span style={{ color: "red" }}> * </span> : '')}:</label>
            <input
                className="form-control"
                id={row_data.id}
                name={row_data.id}
                type={row_data.type}

                value={row_data.value}
                onChange={row_data.onChange}

                min={(row_data.type === "number" ? '0' : '')}

                // data-text-type={row_data.selectrequire}
                placeholder={row_data.placeholder}
                aria-label={row_data.placeholder}


                step={row_data.step}

                required={row_data.required}

            />
        </div>
    )
}
export default RowText;