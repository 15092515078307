import { useContext, useEffect, useState, useMemo } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { MyContext } from '../contexts/MyContext'
// import { CSVLink } from "react-csv";

function ReportDetails(data) {
    let params = useParams();
    const navigate = useNavigate();


    const { rootState, tableGet, getTableWithField, deleteTicket } = useContext(MyContext);
    const [msg, setMsg] = useState();

    const initialState = {
        success: true,
        msdata: [],
        cpdata: [],
        msMsg: '',
        cpMsg: '',

        errorMsg: '',
        successMsg: '',


    }

    const [state, setState] = useState(initialState);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const data = await getTableWithField(params.theId, 'TicketRecordId', 'ticket_manual_start_all_view')
                const data2 = await getTableWithField(params.theId, 'ticket_record_id', 'ticket_coupon_view')

                setState({
                    ...state,
                    success: true,

                    msdata: data.tabledata,
                    cpdata: data2.tabledata,

                    msMsg: data.message,
                    cpMsg: data2.message,
                });

            } catch (error) {
                console.log('#60001 Please find iEnterprise It');
            }
        };
        fetchDetails();
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps




    const deleteTicketBtn = async () => {
        // if (confirm("Press a button!") == true) {
        const choice = window.confirm(
            "Are you sure you want to delete everything?"
        )
        if (choice) {

            const data = await deleteTicket(params.theId, 1);

            setMsg(data.message);
            if (data.success) {
                navigate('/report');
            }
        }

    }


    return data.detaildata.map(row => {
        if (params.theId === row.Id) {
            return (
                <div className="report-detail" key={row.Id}>
                    <div className="row mt-2">
                        <div className="col-sm-11">
                            <a type="button" className="link-primary" onClick={() => navigate(-1)}>Go Back</a>
                        </div>
                        <div className="col-sm-1">
                            {rootState.theUser.type < 3 && <button onClick={deleteTicketBtn}>Delete</button>}
                        </div>
                    </div>

                    <h2>Detail</h2>
                    <section className="mb-4 mt-3 p-2">
                        <div className="row">
                            <h3>FAQ</h3>
                        </div>
                        <RowLabel label="Case Id" text={row.Id} />
                        <RowLabel label="CID" text={row.CID} />
                        <RowLabel label="Type" text={row.Type} />
                        <RowLabel label="Issue" text={row.Issue} />

                        <RowLabel label="FAQ Category" text={row.FAQCat} />
                        <RowLabel label="FAQ" text={row.FAQQuestion} />
                        <RowLabel label="Description" text={row.Description} />

                        {/* <RowLabel label="" text={row.SiteNo} /> */}
                        <RowLabel label="Site" text={row.SiteZhName + " / " + row.SiteEngName} />


                    </section>


                    {row.PenaltyAmount != '' ?
                        <section className="mb-4 mt-3 p-2">
                            <div className="row">
                                <h3>Penalty</h3>
                            </div>
                            <RowLabel label="Penalty Amount" text={row.PenaltyAmount} />
                        </section>
                        : ''}


                    <section className="mb-4 p-2">
                        <div className="row">
                            <h3>Client Data</h3>
                        </div>
                        <RowLabel label="Phone" text={row.Phone} />
                        <RowLabel label="ReplyNo" text={row.ReplyNo} />

                        <RowLabel label="Member No" text={row.MemberNo} />
                        <RowLabel label="Name" text={row.Name} />
                    </section>

                    <section className="mb-4 p-2">
                        <div className="row">
                            <h3>Form Data</h3>
                        </div>
                        <RowLabel label="Check Date" text={row.CheckDate} />
                        <RowLabel label="End Date" text={row.EndDate} />

                        <RowLabel label="Urgent Reply" text={row.UrgentReply} />
                        <RowLabel label="Normal Reply" text={row.NormalReply} />
                        <RowLabel label="Agent" text={row.User} />
                    </section>

                    {state.msMsg === 'Data' && <MSRow data={state.msdata[0]} />}
                    {state.cpMsg === 'Data' && <CpRow data={state.cpdata} />}

                </div>
            )
        }
    })

}

export default ReportDetails;

function RowLabel({ label, text }) {
    return (
        <div className="row g-1 mb-2" >
            <div className="col-sm-2">
                <span>{label}:</span>
            </div>
            <div className="col-sm">
                <span>{text}</span>
            </div>
        </div>
    )
}

function MSRow({ data }) {
    console.log(data)
    return (
        <>
            <h2>Manual Start Detail</h2>
            <section className="mb-4 mt-3 p-2">

                <RowLabel label="Ms Id" text={data.Id} />
                <RowLabel label="Title(NA)" text={data.Title} />
                <RowLabel label="Station(NA)" text={data.ChargingStationName} />
                <RowLabel label="Charging Id" text={data.ChargingId} />
                <RowLabel label="User Platform" text={data.UserPlatform} />
                <RowLabel label="App Version" text={data.AppVersion} />
                <RowLabel label="Phone No.(NA)" text={data.UserPhoneNumber} />
                <RowLabel label="Phone OS" text={data.PhoneOs} />
                <RowLabel label="Phone Model" text={data.PhoneModel} />
                <RowLabel label="Pid" text={data.Pid} />
                <RowLabel label="Plan" text={data.Plan} />
                <RowLabel label="EvModel" text={data.EvModel} />
                <RowLabel label="Final Charged Energy" text={data.FinalChargedEnergy} />
                <RowLabel label="Client Status Time" text={data.ClientStatusTime} />
                <RowLabel label="Error Code" text={data.ErrorCode} />
                <RowLabel label="ProblemD escription" text={data.ProblemDescription} />
                <RowLabel label="Action Done" text={data.ActionDone} />
                <RowLabel label="Result" text={data.MsResult} />
                <RowLabel label="Recharged Result" text={data.MsRechargedResult} />
                <RowLabel label="Refund Type" text={data.MsRefundType} />
            </section>
        </>
    )
}
function CpRow({ data }) {
    // console.log(data)
    return (
        <>
            <h2>Coupon Detail</h2>
            <section className="mb-4 mt-3 p-2">
                {data.map((row, index) =>
                    <RowLabel key={index} label="Coupon Code" text={row.coupon_code} />
                )}
            </section>
        </>
    )
}