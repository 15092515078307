import { useContext, useEffect, useState, useMemo } from 'react'

import { Routes, Route, useParams } from 'react-router-dom';
import { MyContext } from '../contexts/MyContext'
import { CSVLink } from "react-csv";

function RowGetViewTableCSVWithParam({ text }) {
    let { year } = useParams();
    const { tableGetWithParam } = useContext(MyContext);

    const initialState = {
        // url: passdata.url,
        success: true,
        headdata: [],
        tabledata: [],
        errorMsg: '',
        successMsg: '',
    }

    const [state, setState] = useState(initialState);

    useEffect(() => tableGetWithParam(year)
        .then((data) => {
            if (data.success && data.tabledata) {
                setState({
                    ...state,
                    success: true,
                    headdata: Object.keys(data.tabledata[0]),
                    tabledata: data.tabledata,
                });
            } else {
                setState({
                    ...state,
                    success: false,
                    errorMsg: data.message
                });
            }
        }), [year])

    const data = useMemo(() => state.tabledata, [state.tabledata])

    return (state.success) &&
        <Routes>
            <Route path="/" element={
                <div className="col-md-3" style={{ padding: "0", marginRight: "1%" }}>
                    <CSVLink className="form-control btn btn-primary" data={data} filename={text + ".csv"}>{text}</CSVLink>
                </div>
            } />
        </Routes>



};

export default RowGetViewTableCSVWithParam
