import React, { createContext, Component } from "react";
import axios from 'axios'
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    // baseURL: 'http://localhost/data/ticketsys/',
    baseURL: 'https://ticketsys.ienterprise.com.hk/data/',
    // baseURL: 'http://192.168.88.103/data/',
    // baseURL: 'http://localhost/php-login-registration-api/',
});

class MyContextProvider extends Component {
    constructor() {
        super();
        this.isLoggedIn();
    }

    // Root State
    state = {
        showLogin: true,
        isAuth: false,

        UserId: null,
        theUser: null,
    }

    // Toggle between Login & Signup page
    toggleNav = () => {
        const showLogin = !this.state.showLogin;
        this.setState({
            ...this.state,
            showLogin
        })
    }

    // On Click the Log out button
    logoutUser = () => {

        localStorage.removeItem('loginToken');
        this.setState({
            ...this.state,
            isAuth: false
        })
    }



    registerUser = async (user) => {

        // Sending the user registration request
        const register = await Axios.post('register.php', {
            name: user.name,
            email: user.email,
            ac: user.ac,
            password: user.password
        });

        return register.data;
    }


    loginUser = async (user) => {

        // Sending the user Login request
        const login = await Axios.post('login.php', {
            ac: user.ac,
            password: user.password
        });
        return login.data;
    }

    // Checking user logged in or not
    isLoggedIn = async () => {
        const loginToken = localStorage.getItem('loginToken');

        // If inside the local-storage has the JWT token
        if (loginToken) {

            //Adding JWT token to axios default header
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;

            // Fetching the user information
            const { data } = await Axios.get('user-info.php');

            // If user information is successfully received
            if (data.success && data.user) {
                this.setState({
                    ...this.state,
                    isAuth: true,
                    theUser: data.user
                });
            }

        }
    }

    //Get table data
    tableGet = async (phpurl) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const { data } = await Axios.get(phpurl);
            // if (data.success) { return data.tabledata; } else { return data.message; } 
            return data;
        }
    }

    tableGetWithParam = async (year) => {
        // console.log(id)
        // Sending the user registration request
        const newaxios = await Axios.post('ticket-record-get-week-report-v2.php', {
            year: year,
        });

        return newaxios.data;
    }


    getTableWithField = async (fielddata, fieldname, tablename) => {
        // console.log(fielddata, fieldname, tablename)
        const newaxios = await Axios.post('getTableWithField.php', {
            fielddata: fielddata,
            fieldname: fieldname,
            tablename: tablename,
        });

        // console.log(newaxios)

        return newaxios.data;
    }


    //admin function
    //porject create
    projectCreate = async (formdata) => {

        // Sending the user registration request
        const newaxios = await Axios.post('admin-project-create.php', {
            name: formdata.name,
            note: formdata.note,
        });

        return newaxios.data;
    }

    projectCreateResult = async (formdata) => {

        // Sending the user registration request
        // console.log(formdat)
        const newaxios = await Axios.post('admin-project-result-create.php', {
            description: formdata.description,
            text: formdata.text,
        });

        return newaxios.data;
    }

    projectResultActiveGet = async (formdata) => {

        // Sending the user registration request
        console.log(formdata)
        const newaxios = await Axios.post('admin-project-result-type-get.php', {
            porject_id: formdata,
        });
        console.log(newaxios.data)
        return newaxios.data;
    }

    projectResultAdd = async (formdata) => {

        // Sending the user registration request
        // console.log(formdat)
        const newaxios = await Axios.post('admin-project-add-result.php', {
            description: formdata.description,
            text: formdata.text,
        });

        return newaxios.data;
    }

    //new record from porject 
    newTicketRecord = async (formdata) => {

        const newaxios = await Axios.post('ticket-record-new.php', {

            type: formdata.type,
            issue: formdata.issue,

            check_date: formdata.check_date,
            chaeck_time: formdata.chaeck_time,
            end_date: formdata.end_date,
            end_time: formdata.end_time,

            phone: formdata.phone,
            name: formdata.name,
            member_no: formdata.member_no,

            urgent_reply: formdata.urgent_reply,
            normal_reply: formdata.normal_reply,
            reply_no: formdata.reply_no,

            result: formdata.result,
            result_text: formdata.result_text,
            result_need: formdata.result_need,

            site: formdata.site,

            cid: formdata.cid,
            penalty_amount: formdata.penalty_amount,
            coupon_code_need: formdata.coupon_code_need,
            coupon_code_list: JSON.stringify(formdata.coupon_code_list),

            //manual_start_info
            // title: formdata.manual_start_info.title,
            // charging_station_name: formdata.manual_start_info.charging_station_name,
            charging_id: formdata.manual_start_info.charging_id,
            user_platform: formdata.manual_start_info.user_platform,
            app_version: formdata.manual_start_info.app_version,
            // user_phone_number: formdata.manual_start_info.user_phone_number,
            phone_os: formdata.manual_start_info.phone_os,
            phone_model: formdata.manual_start_info.phone_model,
            pid: formdata.manual_start_info.pid,
            plan: formdata.manual_start_info.plan,
            ev_model: formdata.manual_start_info.ev_model,
            final_charged_energy: formdata.manual_start_info.final_charged_energy,
            client_status_time: formdata.manual_start_info.client_status_time,
            error_code: formdata.manual_start_info.error_code,
            problem_description: formdata.manual_start_info.problem_description,
            action_done: formdata.manual_start_info.action_done,
            ms_result: formdata.manual_start_info.ms_result,

            ms_recharged_result: formdata.manual_start_info.ms_recharged_result,
            ms_refund_type: formdata.manual_start_info.ms_refund_type,
            // coupon_code_need: formdata.manual_start_info.coupon_code_need,
            // coupon_code: formdata.manual_start_info.coupon_code,

        });
        // console.log(newaxios.data);
        return newaxios.data;
    }



    deleteTicket = async (id, newstate) => {
        // console.log(id)
        // Sending the user registration request
        const newaxios = await Axios.post('ticket-record-delete.php', {
            id: id,
            newstate: newstate,
        });

        return newaxios.data;
    }


    render() {
        const contextValue = {
            //base
            rootState: this.state,
            toggleNav: this.toggleNav,
            isLoggedIn: this.isLoggedIn,
            registerUser: this.registerUser,
            loginUser: this.loginUser,
            logoutUser: this.logoutUser,

            //Get table
            tableGet: this.tableGet,
            tableGetWithParam: this.tableGetWithParam,
            // msDataGet: this.msDataGet,


            //project
            // projectGet: this.projectGet, 
            newTicketRecord: this.newTicketRecord,
            getTableWithField: this.getTableWithField,
            deleteTicket: this.deleteTicket,


        }
        return (
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;