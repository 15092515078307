import { Routes, Route, Link } from "react-router-dom";
import RowGetViewTableLong from "../components/RowGetViewTableLong";
import RowGetViewTableLongWithParam from "../components/RowGetViewTableLongWithParam";
import RowGetViewTableCSV from "../components/RowGetViewTableCSV";
import RowGetViewTableCSVWithParam from "../components/RowGetViewTableCSVWithParam";

import SelectColumnFilter from "../components/ReactTable/SelectColumnFilter";

function Report() {
  const ticketcolumns = [
    { Header: "Id", accessor: "Id", disableFilters: true },
    {
      Header: "FAQCat",
      accessor: "FAQCat",
      Filter: SelectColumnFilter,
      filter: "excludes",
      Cell: (rows) => (
        <span
          title={
            rows.row.original.FAQNo === "99"
              ? "Other: " + rows.row.original.Description
              : rows.row.original.FAQNo + " " + rows.row.original.FAQQuestion
          }
        >
          {rows.value}
        </span>
      ),
    },
    {
      Header: "Site",
      accessor: "SiteNo",
      Filter: SelectColumnFilter,
      filter: "excludes",
      Cell: (rows) => (
        <span
          title={
            rows.row.original.SiteZhName + " / " + rows.row.original.SiteEngName
          }
        >
          {/* {rows.value} */}
          {rows.row.original.SiteZhName + " / " + rows.row.original.SiteEngName}
        </span>
      ),
    },
    {
      Header: "Type",
      accessor: "Type",
      Filter: SelectColumnFilter,
      filter: "excludes",
    },

    { Header: "Phone", accessor: "Phone" },

    { Header: "Date", accessor: "CheckDate" },

    {
      Header: "Reply",
      Cell: (rows) =>
        rows.row.original.UrgentReply === "Yes"
          ? "Pass to Tier 2"
          : rows.row.original.NormalReply === "Yes"
          ? "CS Agent replied"
          : "Settled",
    },

    { Header: "Agent", accessor: "User", disableFilters: true },
    {
      Header: "Details",
      Cell: (rows) => (
        <Link to={"/report/tickentreport/" + rows.row.original.Id}>More</Link>
      ),
    },
  ];

  const mscolumns = [
    { Header: "Ms Id", accessor: "Id", disableFilters: true },

    { Header: "Ticket Record Id", accessor: "TicketRecordId" },
    { Header: "Title", accessor: "Title" },
    { Header: "Name", accessor: "Name" },
    {
      Header: "Charging",
      Cell: (rows) => (
        <span
          title={
            rows.row.original.SiteZhName + " / " + rows.row.original.SiteEngName
          }
        >
          {rows.row.original.SiteZhName + " / " + rows.row.original.SiteEngName}
        </span>
      ),
    },
    { Header: "Charging ID", accessor: "ChargingId" },
    { Header: "User Platform", accessor: "UserPlatform" },

    { Header: "App Version", accessor: "AppVersion" },
    { Header: "User Phone Number", accessor: "UserPhoneNumber" },
    { Header: "Phone OS", accessor: "PhoneOs" },
    { Header: "Phone Model", accessor: "PhoneModel" },
    { Header: "Pid", accessor: "Pid" },
    { Header: "Plan", accessor: "Plan" },
    { Header: "Ev Model", accessor: "EvModel" },
    { Header: "Final Charged Energy", accessor: "FinalChargedEnergy" },
    { Header: "Client Status Time", accessor: "ClientStatusTime" },
    { Header: "Error Code", accessor: "ErrorCode" },
    { Header: "Problem Description", accessor: "ProblemDescription" },
    { Header: "Action Done", accessor: "ActionDone" },
    { Header: "Ms Result", accessor: "MsResult" },
    { Header: "User", accessor: "User" },
    {
      Header: "Details",
      Cell: (rows) => (
        <Link to={"/report/tickentreport/" + rows.row.original.TicketRecordId}>
          More
        </Link>
      ),
    },
  ];

  const weekcolumns = [
    { Header: "Week", accessor: "Week", disableFilters: true },
    {
      Header: "Date",
      Cell: (rows) =>
        rows.row.original.StartDate + " to " + rows.row.original.EndDate,
    },

    { Header: "By Phone", accessor: "Phone" },
    { Header: "By WhatsApp", accessor: "WhatsApp" },
    { Header: "By AMS", accessor: "AMS" },

    { Header: "Total Cases", accessor: "Total" },
    { Header: "Pass to tier 2", accessor: "Tier2" },
    { Header: "CS Success Handle", accessor: "CSSuccess" },
    { Header: "Manual Start", accessor: "MS" },
    { Header: "Manual Start (Success)", accessor: "MSS" },
    { Header: "Fail Charging and Auto Refund", accessor: "AutoRefund" },
    { Header: "Insufficient Charging", accessor: "InsufficientCharging" },
    { Header: "Other Charging issue", accessor: "OtherFail" },
    { Header: "Penalty", accessor: "Penalty" },
    { Header: "Waive Penalty", accessor: "WaivePenalty" },
    { Header: "Total Charing", accessor: "Charing" },
    {
      Header: "Charing Issue %",
      Cell: (rows) =>
        ((rows.row.original.Charing / rows.row.original.Total) * 100).toFixed(
          2
        ) + "%",
    },
    {
      Header: "Enquiry Issue %",
      Cell: (rows) =>
        ((rows.row.original.Enquiry / rows.row.original.Total) * 100).toFixed(
          2
        ) + "%",
    },
    { Header: "Fail and Refund Request", accessor: "Refund" },
    { Header: "Fail and Coupon Refund", accessor: "CouponRefund" },
    { Header: "EvModel", accessor: "EvModel" },
  ];

  const thisyear = new Date().getFullYear();

  return (
    <div className="row">
      <nav>
        <Link to="tickentreport">Ticket Report</Link> |{" "}
        <Link to="msreport">Manual Start Report</Link> |{" "}
        <Link to={"weeklyreport/" + thisyear}>Weekly Report</Link>
      </nav>
      <h1>Report</h1>
      <Routes>
        <Route
          index
          element={
            <>
              <div className="row" style={{ marginBottom: "1%" }}>
                <RowGetViewTableCSV
                  url="ticket-record-get-all.php"
                  text="Download All Data"
                />
                <RowGetViewTableCSV
                  url="ticket-record-get-week.php"
                  text="Download last 7 day Data"
                />
              </div>
              <div className="row">
                <RowGetViewTableLong
                  url="ticket-record-get-all.php"
                  col={ticketcolumns}
                />
              </div>
            </>
          }
        />

        <Route
          path="/tickentreport/*"
          index
          element={
            <>
              <div className="row" style={{ marginBottom: "1%" }}>
                <RowGetViewTableCSV
                  url="ticket-record-get-all.php"
                  text="Download All Data"
                />
                <RowGetViewTableCSV
                  url="ticket-record-get-week.php"
                  text="Download last 7 day Data"
                />
              </div>
              <div className="row">
                <RowGetViewTableLong
                  url="ticket-record-get-all.php"
                  col={ticketcolumns}
                />
              </div>
            </>
          }
        />

        <Route
          path="/msreport/*"
          element={
            <>
              <div className="row" style={{ marginBottom: "1%" }}>
                <RowGetViewTableCSV
                  url="ticket-manual-start-get-all.php"
                  text="Download Manual Start Data"
                />
              </div>
              <div className="row">
                <RowGetViewTableLong
                  url="ticket-manual-start-get-all.php"
                  col={mscolumns}
                />
              </div>
            </>
          }
        />

        <Route
          path="/weeklyreport/:year"
          element={
            <>
              <nav className="row">
                {[...Array(thisyear - 2022 + 1)].map((_, i) => (
                  <Link
                    key={i}
                    to={"/report/weeklyreport/" + (2022 + i)}
                    className="col-1"
                  >
                    {2022 + i}
                  </Link>
                ))}
              </nav>

              <div className="row" style={{ marginBottom: "1%" }}>
                <RowGetViewTableCSVWithParam text="Download Week Total Report" />
              </div>
              <div className="row">
                <RowGetViewTableLongWithParam
                  url="ticket-record-get-week-report.php"
                  col={weekcolumns}
                />
              </div>
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default Report;
