import { useContext, useEffect, useState } from 'react'
import { MyContext } from '../contexts/MyContext'
import RowTable from './rows/RowTable'

function RowGetViewTable(passdata) {
    const { tableGet } = useContext(MyContext);

    const initialState = {
        // url: passdata.url,
        success: true,
        headdata: [],
        tabledata: [],
        errorMsg: '',
        successMsg: '',
    }

    const [state, setState] = useState(initialState);

    useEffect(() => tableGet(passdata.url)
        .then((data) => {
            if (data.success && data.tabledata) {
                setState({
                    ...state,
                    success: true,
                    headdata: Object.keys(data.tabledata[0]),
                    tabledata: data.tabledata,
                });
            } else {
                setState({
                    ...state,
                    success: false,
                    errorMsg: data.message
                });
            }
        }), [passdata])


    return (state.success) ? <RowTable head={state.headdata} data={state.tabledata} /> : <div>{state.errorMsg}</div>



};

export default RowGetViewTable
