function RowTable(tabledata) {
    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    {tabledata.head.map((title) =>
                        <th key={title} scope="col">{title}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {tabledata.data.map((rows) =>
                    <tr key={rows.Id}>
                        {Object.keys(rows).map((row) =>
                            (row === 'Id') ?
                                <th key={row} >{rows[row]}</th> : <td key={row}>{rows[row]}</td>
                        )}
                    </tr>
                )}
            </tbody>
        </table>
    )
};

export default RowTable
