function ItemRadio(ItemRadio) {
    return (
        // <div className="form-check form-check-inline">
        <div className="form-check">
            <input className="form-check-input"
                type="radio"
                name={ItemRadio.name}
                id={ItemRadio.id}
                value={ItemRadio.value}
                onChange={ItemRadio.onChange}
                // checked={ItemRadio.checked}
                data-text-type={ItemRadio.textrequire}
                defaultChecked={ItemRadio.defaultChecked}
            />
            <label className="form-check-label" htmlFor={ItemRadio.id}>{ItemRadio.text}</label>
        </div>
    );
}
export default ItemRadio;