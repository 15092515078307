import { useContext, useState } from 'react'
import { MyContext } from '../../contexts/MyContext'

function Login() {

    const { loginUser, isLoggedIn } = useContext(MyContext);

    const initialState = {
        userInfo: {
            ac: '',
            password: '',
        },
        errorMsg: '',
        successMsg: '',
    }

    const [state, setState] = useState(initialState);

    // On change input value (ac & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if (data.success && data.token) {
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else {
            setState({
                ...state,
                successMsg: '',
                errorMsg: data.message
            });
        }
    }

    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }



    return (
        <>
            <h1>Ticketing System</h1>
            <form onSubmit={submitForm} noValidate>
                <div className="mb-3">
                    <label htmlFor="ac" className="form-label">Login Name: </label>
                    <input className="form-control" name="ac" type="text" required placeholder="Enter your ac" value={state.userInfo.ac} onChange={onChangeValue} />
                </div>
                <div className="mb-3">
                    <label htmlFor="ac" className="form-label">PassWord: </label>
                    <input className="form-control" name="password" type="password" required placeholder="Enter your password" value={state.userInfo.password} onChange={onChangeValue} />
                </div>
                {errorMsg}
                {successMsg}
                <div className="row mb-3">
                    <div className="col">
                        <button className="btn btn-primary" type="submit">Login</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Login;