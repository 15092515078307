import { useContext, useEffect, useState, useMemo } from 'react'

import { Routes, Route, useParams } from 'react-router-dom';
import { MyContext } from '../contexts/MyContext'
import RowReactTableFilter from './ReactTable/RowReactTableFilter'
// import SelectColumnFilter from './ReactTable/SelectColumnFilter'

import ReportDetails from '../pages/ReportDetails';
// import { CSVLink } from "react-csv";

function RowGetViewTableLongWithParam(passdata) {
    let { year } = useParams();
    const { tableGetWithParam } = useContext(MyContext);

    const initialState = {
        // url: passdata.url,
        success: true,
        // headdata: [],
        // id: '',
        tabledata: [],
        errorMsg: '',
        successMsg: '',
    }

    const [state, setState] = useState(initialState);

    useEffect(() => tableGetWithParam(year)
        .then((data) => {
            if (data.success && data.tabledata) {
                setState({
                    ...state,
                    success: true,
                    tabledata: data.tabledata,
                });
            } else {
                setState({
                    ...state,
                    success: false,
                    errorMsg: data.message
                });
            }

        }), [year])

    const data = useMemo(() => state.tabledata, [state.tabledata])

    const columns = useMemo(() => passdata.col, [passdata.col])


    return (state.success) ?
        <Routes>
            <Route path="/" element={<RowReactTableFilter columns={columns} data={data} />} />
            <Route path=":theId" element={<ReportDetails detaildata={data} />} />
        </Routes>
        : <>{state.errorMsg}</>

};

export default RowGetViewTableLongWithParam
