import { useContext, useEffect, useState } from 'react'
import { MyContext } from '../contexts/MyContext'
import RowItemRadioWithNo from './rows/RowItemRadioWithNo';

function NewTicketFAQ(Passdata) {
    const { tableGet } = useContext(MyContext);

    let title = {
        1: "插頭標準",
        2: "收費",
        3: "充電操作",
        4: "時間",
        5: "速度",
        6: "車位",
        7: "其他",
        8: "一般操作",
        9: "充電站",
        10: "Manual start",
        11: "Refund",
        12: "Fail Charge",
        13: "Penalty",
        14: "登記",
    }

    const initialState = {
        // url: Passdata.url,
        success: true,
        headdata: [],
        tabledata: [],
        category: '',
        errorMsg: '',
        successMsg: '',
    }

    const [state, setState] = useState(initialState);

    useEffect(() => tableGet(Passdata.url)
        .then((data) => {

            if (data.success && data.tabledata) {
                setState({
                    ...state,
                    success: true,
                    headdata: Object.keys(data.tabledata[0]),
                    tabledata: data.tabledata,
                    category: Passdata.category
                });


            } else {
                setState({
                    ...state,
                    success: false,
                    errorMsg: data.message
                });
            }
        }), [Passdata])

    return (
        <section className="mb-4 mt-3 p-2">
            <div className="row">
                <h3>FAQ: {title[Passdata.category]}</h3>
            </div>
            {Passdata.category === "9" && <No2Add />}

            {state.tabledata.map((rows) => (rows["Category Id"] === Passdata.category &&
                <div className='row' key={rows["Id"]}>
                    <div className='col-12 mb-2 mt-1'>
                        {/* Qusetion */}
                        <RowItemRadioWithNo
                            name='result'
                            id={'result' + rows["Id"]}
                            onChange={Passdata.onChange}
                            textrequire={Passdata.textrequire}
                            text={rows["Question"]}
                            value={rows["Id"]}
                        />

                    </div>
                </div>
            ))}
        </section>
    )
};

export default NewTicketFAQ


function LoopAnswer(rows) {
    switch (rows.data["Id"]) {
        case "1": return <Q1 answer={rows.data["Answer"]} />;
        case "4": return <Q4 answer={rows.data["Answer"]} />;
        case "5": return <Q5 answer={rows.data["Answer"]} />;
        case "15": return <Q15 answer={rows.data["Answer"]} />;
        case "17": return <Q17 answer={rows.data["Answer"]} />;
        case "18": return <Q18 answer={rows.data["Answer"]} />;
        case "20": return <Q20 answer={rows.data["Answer"]} />;
        case "29": return <Q29 answer={rows.data["Answer"]} />;
        case "30": return <Q30 answer={rows.data["Answer"]} />;
        case "31": return <Q31 answer={rows.data["Answer"]} />;
        case "33": return <Q33 answer={rows.data["Answer"]} />;
        case "34": return <Q34 answer={rows.data["Answer"]} />;
        case "35": return <Q35 answer={rows.data["Answer"]} />;
        case "37": return <Q37 answer={rows.data["Answer"]} />;
        case "38": return <Q38 answer={rows.data["Answer"]} />;
        case "39": return <Q39 answer={rows.data["Answer"]} />;
        default: return "答： " + rows.data["Answer"]
    }
}

function Q1(data) {
    return (
        <section>
            答：{data.answer}
            <table className='faqtable'>
                <tbody>
                    <tr>
                        <td>AC<br />中叉</td>
                        <td>Type 1 SAE J1772<br /><img src='/img/Picture1.png' alt='' /></td>
                        <td>Type 2 IEC<br /><img src='/img/Picture2.png' alt='' /></td>
                    </tr>
                    <tr>
                        <td>Output Power</td>
                        <td>Standard -  7kW</td>
                        <td>Standard – 7kW Max (21kW)</td>
                    </tr>
                    <tr>
                        <td>DC<br />快叉</td>
                        <td>CHAdeMo<br /><img src='/img/Picture3.png' alt='' /></td>
                        <td>CCS Combo 2<br /><img src='/img/Picture4.png' alt='' /></td>
                    </tr>
                    <tr>
                        <td>Output Power</td>
                        <td>Max -  60kW</td>
                        <td>Max – 350kW</td>
                    </tr>
                    <tr>
                        <td>Brand</td>
                        <td>NISSAN</td>
                        <td>AUDI<br />
                            BMW<br />
                            HYUNDAI<br />
                            JAGUAR<br />
                            KIA<br />
                            MERCEDES BENZ<br />
                            MG<br />
                            RENAULT<br />
                            PORSCHE<br />
                            SMART<br />
                            TESLA<br />
                            VOLVO<br />
                            VW<br />
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    )
}

function Q4(data) {
    return (
        <section>
            答：{data.answer}<br />
            <img src='/img/Picture5.png' alt='' /><br />
            <img src='/img/Picture6.png' alt='' />
        </section>
    )
}

function Q5(data) {
    return (
        <section>
            答：{data.answer}<br />
            <img src='/img/Picture7.png' alt='' /><br />
            <img src='/img/Picture8.png' alt='' />
        </section>
    )
}

function Q15(data) {
    return (
        <section>
            答：不同的充電服務, 會有不同安排: <br />
            快充:  逾時5分鐘, 佔用費每分鐘$1<br />
            日充夜充, 即時充: 逾時15分鐘, 佔用費每分鐘$1<br />
            逾時的費用， 會在下次開始充電前收取。<br />
            <br />
        </section>
    )
}

function Q17(data) {
    return (
        <section>
            答：宜加充電一般的充電服務是按時收費, 並在指定的時間內提供最多的電量, 例如:<br />
            AC Hourly 2: $35 可充2小時, 最多可達14kWh<br />
            假如在2小時內, 充電量已達14kWh, 充電便會暫定, 如此類推。<br />
            <br />
        </section>

    )
}

function Q18(data) {
    return (
        <section>
            答：<br />
            請留意, 開始充電前, 請先登入https://epluscharging.com/login，然後才掃瞄QR Code<br />
            如果情況繼續, 有可能因為:<br />
            1.	你使用了iPhone/Android 原本的QR scanner<br />
            2.	你選了瀏覽器以外的QR 開啟方法<br />
            如果面對這個情況, 可以選擇:<br />
            1.	登入後, 到 “My Car”裡, 選擇需要充電的EV<br />
            2.	在PID裡輸入在充電器上的ID (顯示在QR Code的下面)<br />
            <br />
        </section>
    )
}

function Q20(data) {
    return (
        <section>
            答：如果未能啟動充電, 可以嘗試以下檢查:<br />
            1.	檢查充電槍是否連接好;<br />
            2.	檢查你的手機能否連結網絡上網, 部份停車場的電訊訊號接收比較差, 你可以使用宜加充電提供的WiFi連結網絡去使用充電服務;<br />
            3.	檢查是否已登入宜加充電平台, 如果在掃瞄QR Code後, 才登入平台, 請再掃瞄多一次QR Code<br />
            4.	如果已經做好以上檢查仍然未能啟動充電, 可以直接聯絡我們<br />
        </section>
    )
}

function Q29(data) {
    return (
        <section>
            答：<br />
            1.	使用充電器前，請先登記 (https://epluscharging.com/registration)，然後登入(https://epluscharging.com/login)。<br />
            2.	插上充電插槍。<br />
            3.	掃描充電器上的二維碼QR Code，進入充電計劃選項。<br />
            4.	點選所需要充電計劃。<br />
            5.	選擇 ”下一步”，查看充電細節，如有優惠券，可以此選用。<br />
            6.	選擇付款方式，然後前往結帳。<br />
            7.	完成後充電器便開始充電。<br />
            8.	充電途中，可按入查看狀況。<br />
            9.	如有需要, 可以按下 ”停止充電” 以離開。<br />
            10.	拔出充電槍後，將充電線放回掛勾。<br />
            <br />
        </section>
    )
}

function Q30(data) {
    return (
        <section>
            答：<br />
            1.	任何剩餘未使用的充電量將不會累積。<br />
            2.	用戶必須配合管理處有關泊車安排，如有任何爭議，會按屋苑一般程序處理。<br />
            3.	如果充電車位正在被使用中，用戶須按照管理處指示，在合適范圍內等候。<br />
            4.	如有任何爭議, 希路能源有限公司保留更改優惠之最終決定權。<br />
            <br />
        </section>
    )
}

function Q31(data) {
    return (
        <section>
            答：如果由於停車場內的電話訊號接收不良, 為確保用戶可以穩定使用充電服務, 宜加充電特別為用戶提供30分鐘的WiFi 服務。所以在使用充電服務時, 用戶必須先連接到 ”EplusCharging_Guest”以確保安心使用宜加充電。<br />
            <img src='/img/Picture9.png' alt='' /><br />
            <br />
        </section>
    )
}



function Q33(data) {
    return (
        <section>
            <div className='row'>
                <div className='col-12'>
                    <img style={{ width: "100%" }} src='/img/site.png' alt='' />
                </div>
            </div>
            <br />

            <div className='row'>
                <div className='col-6'>

                    <img style={{ width: "100%" }} src='/img/site1.png' alt='' />
                </div>
                <div className='col'>

                    <img style={{ width: "100%" }} src='/img/site2.png' alt='' />
                </div>
            </div>
            <br />

            <div className='row'>
                <div className='col'>
                    <img style={{ width: "100%" }} src='/img/site3.png' alt='' />
                </div>
                <div className='col'>
                    <img style={{ width: "100%" }} src='/img/site4.png' alt='' />
                </div>
            </div>
            <br />
        </section>)
}

function Q34(data) {
    return (
        <section>
            答：{data.answer}<br />
            <div className='row'>
                <img src='/img/Picture10.png' alt='' />
            </div>
        </section>
    )
}


function Q35(data) {
    return (
        <section>
            答：{data.answer}<br />
            <div className='row'>
                <img src='/img/Picture11.png' alt='' />
            </div>
        </section>
    )
}

function Q37(data) {
    return (
        <section>
            答：{data.answer}<br />
            - 如TESLA車主，請他們使用TESLA手機應用程式，在充電頁面上多嘗試按閃電標誌並查閱解鎖按鈕。<br />
            - 如必須使用緊急制，請CS同事確認客戶已將緊急制在解決問題後回復原位。<br />
        </section>
    )
}


function Q38() {
    return (
        <section>
            答：<br />
            1.	使用充電器前，請先登記 (https://shellrecharge.epluscharging.com/registration)，然後登入(https://shellrecharge.epluscharging.com/login)。<br />
            2.	插上充電插槍。<br />
            3.	掃描充電器上的二維碼QR Code，進入充電計劃選項。<br />
            4.	點選所需要充電計劃。<br />
            5.	選擇 ”下一步”，查看充電細節，如有優惠券，可以此選用。<br />
            6.	選擇付款方式，然後前往結帳。<br />
            7.	完成後充電器便開始充電。<br />
            8.	充電途中，可按入查看狀況。<br />
            9.	如有需要, 可以按下 ”停止充電” 以離開。<br />
            10.	拔出充電槍後，將充電線放回槍座。<br />
            <br />
        </section>
    )
}

function Q39() {
    return (
        <section>
            填寫手動開機表格
        </section>
    )
}



function No2Add() {
    return (
        <section>
            <div className='row'>
                <div className='col-12'>
                    <img style={{ width: "100%" }} src='/img/site.png' alt='' />
                </div>
            </div>
            <br />

            <div className='row'>
                <div className='col-6'>

                    <img style={{ width: "100%" }} src='/img/RP.png' alt='' />
                    <img style={{ width: "100%" }} src='/img/RP1.png' alt='' />
                </div>
                <div className='col'>

                    <img style={{ width: "100%" }} src='/img/GCP.png' alt='' />
                    <img style={{ width: "100%" }} src='/img/GCP1.png' alt='' />
                    <img style={{ width: "100%" }} src='/img/GCP2.png' alt='' />
                </div>
            </div>
            <br />

            <div className='row'>
                <div className='col'>
                    <img style={{ width: "100%" }} src='/img/SL.png' alt='' />
                    <img style={{ width: "100%" }} src='/img/SL1.png' alt='' />
                </div>
                <div className='col'>
                    <img style={{ width: "100%" }} src='/img/Cloud.png' alt='' />
                    <img style={{ width: "100%" }} src='/img/Cloud1.png' alt='' />
                </div>
            </div>
            <br />


            <div className='row'>
                <div className='col'>
                    <img style={{ width: "100%" }} src='/img/Ardshiel.png' alt='' />
                    <img style={{ width: "100%" }} src='/img/Ardshiel1.png' alt='' />
                </div>
                <div className='col'>
                    <img style={{ width: "100%" }} src='/img/CHKC.png' alt='' />
                    <img style={{ width: "100%" }} src='/img/CHKC1.png' alt='' />
                    <img style={{ width: "100%" }} src='/img/CHKC2.png' alt='' />
                </div>
            </div>
            <br />

            <div className='row'>
                <div className='col-6'>
                    <img style={{ width: "100%" }} src='/img/site7.png' alt='' />
                </div>
            </div>
            <br />
        </section>)
}