// import logo from './logo.svg';
// import { Routes, Route, Link } from "react-router-dom";
// import './App.css';
import MyContextProvider from './contexts/MyContext';
import RouterDom from './router/RouterDom'

function App() {
  return (
    <MyContextProvider>
      <RouterDom />
    </MyContextProvider>
  );
}

export default App;
